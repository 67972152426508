import del from './del'
import detail from './detail'
import match from './match'
import upload from './upload'

export default {
  del,
  upload,
  detail,
  match
}
